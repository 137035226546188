<template>
    <div>Placeholder Login Modal</div>
</template>

<script>
    export default {
        name: 'TheLogin',
        beforeRouteEnter( to, from, next ) {
            next( false );
        },
    };
</script>